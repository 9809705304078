<template>
  <div class="forth-cover">
    <div class="forth-screen">
      <div class="forth-left">
        <div class="left-top">跨平台云服务<br />确保数据安全</div>
        <div class="left-middle">
          支持Windows，Mac，Linux，iOS，Android多端协同工作，并严格确保数据和信息安全。
        </div>
        <div @click="$router.push('/businessCooperation')" class="left-bottom">
          <span>预约演示</span>
          <span class="el-icon-arrow-right"></span>
        </div>
      </div>
      <img class="right-img" src="../../assets/images/b-forth-1.png" alt="" />
    </div>
    <div class="customized-need">
      <div class="need-left">
        <div class="left-top">定制化集团企业需求</div>
        <div class="left-list">
          <div class="list-item">
            <img
              class="item-icon"
              src="../../assets/images/b-forth-icon1.png"
              alt=""
            />
            <span class="item-text">专属二级域名</span>
          </div>
          <div class="list-item list-item1">
            <img
              class="item-icon"
              src="../../assets/images/b-forth-icon2.png"
              alt=""
            />
            <span class="item-text">品牌Logo显示</span>
          </div>
          <div class="list-item">
            <img
              class="item-icon"
              src="../../assets/images/b-forth-icon3.png"
              alt=""
            />
            <span class="item-text">可对接集团全屋智能系统</span>
          </div>
          <div class="list-item list-item2">
            <img
              class="item-icon"
              src="../../assets/images/b-forth-icon4.png"
              alt=""
            />
            <span class="item-text">专业技术支持</span>
          </div>
        </div>
      </div>
      <img class="right-bg" src="../../assets/images/b-forth-bg.png" alt="" />
      <img
        class="cover-img"
        src="../../assets/images/b-forth-cover.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
};
</script>

<style scoped lang='scss'>
.forth-cover {
  background: #f2f5fa;
  padding-bottom: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 1000px) {
    padding-bottom: 100px;
  }
  @media screen and (max-width: 749px) {
    padding-bottom: 70px;
  }
}
.forth-screen {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1200px;
  margin: 0 auto;
  @media screen and (max-width: 1299px) {
    width: 87.89%;
  }
  @media screen and (max-width: 1000px) {
    width: 83.5%;
  }
  @media screen and (max-width: 749px) {
    width: 85.333%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .forth-left {
    margin-right: 140px;
    @media screen and (max-width: 1299px) {
      margin-right: 120px;
    }
    @media screen and (max-width: 1000px) {
      margin-right: 54px;
    }
    @media screen and (max-width: 749px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-right: 0px;
      text-align: center;
    }
    .left-top {
      font-size: 36px;
      font-weight: 500;
      line-height: 48px;
      @media screen and (max-width: 1299px) {
        font-size: 32px;
        line-height: 44px;
      }
      @media screen and (max-width: 1000px) {
        font-size: 28px;
        line-height: 40px;
      }
    }
    .left-middle {
      font-size: 18px;
      color: #666;
      line-height: 32px;
      margin-top: 27px;
      @media screen and (max-width: 1299px) {
        margin-top: 19px;
      }
      @media screen and (max-width: 1000px) {
        font-size: 16px;
        line-height: 30px;
        margin-top: 17px;
      }
    }
    .left-bottom {
      color: #2f56ee;
      font-size: 18px;
      margin-top: 33px;
      @media screen and (max-width: 1000px) {
        font-size: 16px;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  .right-img {
    width: 560px;
    height: 360px;
    @media screen and (max-width: 1299px) {
      width: 480px;
      height: 308px;
    }
    @media screen and (max-width: 1000px) {
      width: 340px;
      height: 218px;
    }
    @media screen and (max-width: 749px) {
      width: 85.33%;
      height: auto;
      margin-top: 80px;
    }
  }
}
.customized-need {
  width: 1200px;
  height: 360px;
  background: linear-gradient(270deg, #709bff 0%, #3458bd 100%);
  border-radius: 30px;
  margin-top: 140px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding-left: 90px;
  position: relative;
  @media screen and (max-width: 1299px) {
    width: 87.89%;
    height: 300px;
    margin-top: 120px;
    padding-left: 60px;
  }
  @media screen and (max-width: 1000px) {
    width: 83.5%;
    height: 250px;
    margin-top: 100px;
    padding-left: 50px;
  }
  @media screen and (max-width: 749px) {
    width: 85.33%;
    height: 520px;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 0px;
  }
  .need-left {
    @media screen and (max-width: 749px) {
      width: 100%;
    }
    .left-top {
      font-size: 40px;
      color: #fff;
      @media screen and (max-width: 1299px) {
        font-size: 36px;
      }
      @media screen and (max-width: 1000px) {
        font-size: 28px;
      }
      @media screen and (max-width: 749px) {
        padding: 50px 34px 34px;
        text-align: center;
        line-height: 40px;
      }
    }
    .left-list {
      width: 543px;
      font-size: 22px;
      color: #eee;
      margin-top: 50px;
      display: flex;
      flex-wrap: wrap;
      @media screen and (max-width: 1000px) {
        width: 429px;
        font-size: 16px;
        margin-top: 40px;
      }
      @media screen and (max-width: 749px) {
        width: 100%;
        padding-left: 50px;
        box-sizing: border-box;
        flex-direction: column;
        margin-top: 0px;
      }
      .list-item {
        display: flex;
        align-items: center;
        margin-bottom: 25px;
        @media screen and (max-width: 749px) {
          &:last-child {
            margin-bottom: 8px;
          }
        }
        .item-icon {
          width: 32px;
          height: 32px;
          @media screen and (max-width: 1000px) {
            width: 26px;
            height: 26px;
          }
        }
        .item-text {
          margin-left: 10px;
        }
      }
      .list-item1 {
        margin-left: 174px;
        @media screen and (max-width: 1000px) {
          margin-left: 104px;
        }
        @media screen and (max-width: 749px) {
          margin-left: 0px;
        }
      }
      .list-item2 {
        margin-left: 63px;
        @media screen and (max-width: 1000px) {
          margin-left: 30px;
        }
        @media screen and (max-width: 749px) {
          margin-left: 0px;
        }
      }
    }
  }
  .right-bg {
    width: 360px;
    height: 360px;
    @media screen and (max-width: 1299px) {
      width: 300px;
      height: 300px;
    }
    @media screen and (max-width: 1000px) {
      width: 215px;
      height: 215px;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
  .cover-img {
    width: 340px;
    height: 250px;
    position: absolute;
    right: 120px;
    top: 50%;
    transform: translateY(-50%);
    @media screen and (max-width: 1299px) {
      width: 280px;
      height: 205px;
      right: 38px;
    }
    @media screen and (max-width: 1000px) {
      width: 200px;
      height: 147px;
      right: 25px;
    }
    @media screen and (max-width: 749px) {
      width: 200px;
      height: 147px;
      top: 333px;
      left: 50%;
      transform: translate(-50%,0);
    }
  }
}
</style>