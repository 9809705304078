<template>
  <div class="third-screen">
    <div class="third-header">应用场景和价值</div>
    <div class="third-main">
      <div class="main-top">
        <el-carousel
          class="top-left"
          ref="carousel"
          :autoplay="autoplay"
          arrow="never"
          indicator-position="none"
          :interval="4000"
          @change="carouselChange"
        >
          <el-carousel-item
            :initial-index="item.value"
            class="left-item"
            v-for="item in valueArr"
            :key="item.value"
          >
            <div class="item-top">{{ item.text }}</div>
            <div class="item-bottom">
              {{ item.content }}
            </div>
          </el-carousel-item>
        </el-carousel>
        <img
          class="right-img"
          src="../../assets/images/b-third-top.png"
          alt=""
        />
      </div>
      <div class="main-bottom">
        <div
          @mouseenter="mouseEnter(0)"
          :class="[checkedValue == 0 ? 'img-cover-a' : '']"
          class="img-cover"
        >
          <img
            class="inner-img"
            src="../../assets/images/b-third-1.png"
            alt=""
          />
          <div class="img-active"></div>
          <div class="inner-text">海量的全国<br />经销商管理</div>
        </div>
        <div
          @mouseenter="mouseEnter(item.value)"
          v-for="item in valueArr.slice(1, 5)"
          :key="item.value"
          class="img-cover"
          :class="[checkedValue == item.value ? 'img-cover-a' : '']"
        >
          <img
            class="inner-img"
            :src="require(`../../assets/images/b-third-${item.value + 1}.png`)"
            alt=""
          />
          <div class="img-active"></div>
          <div class="inner-text">{{ item.text }}</div>
        </div>
      </div>
    </div>
    <div class="dot-list">
      <div
        @mouseenter="mouseEnter(item.value)"
        :class="[checkedValue == item.value ? 'dot-active' : '']"
        v-for="item in valueArr"
        :key="item.value"
        class="dot"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      autoplay:false,
      valueArr: [
        {
          text: "海量的全国经销商管理",
          value: 0,
          content:
            "数字化是品牌商管理经销商的最佳方式，也是现在的行业趋势。通过和慕PMS，品牌商可以和自己的经销商连接，能够获取经销商现在的接单、实施、交付情况，掌握全流程的设计安装文档。实现全面一体的人员在线化、客户在线化、订单在线化、市场活动在线化。企业主管对于经销商的销售额统计、增长率等能够如数家珍的话，即表明他对经销商的管理工作做得很好，同时对经销商的管理也很完善。经销商管理数字化是非常重要的企业战略。",
        },
        {
          text: "集团自建的第一方设计安装服务支持",
          value: 1,
          content:
            "几乎所有的全屋智能品牌商都为经销商提供自建的的设计和调试服务，众多的部门如何协同，跨部门的协同管理并不容易，显然有数字化改进的机会。如何分派服务人员，让服务人员如何融入经销商项目组，如何管理服务人员的工作绩效，如何保证服务标准的统一。没有一款良好的工具，这些都是耗费大量精力都难以做好的。",
        },
        {
          text: "全屋智能服务的长期性和延续性",
          value: 2,
          content:
            "与一般产品的销售不同，全屋智能服务是一个持续的不间断的过程。全屋智能品牌商必须保证设备的长时间运行，在服务合同有效期内的任何服务中断，都有可能导致业主的投诉和违约的追究。售后服务的持续性和更换维修设备的巨大成本，对企业来说也是个巨大的考验。另外经销商和服务人员还存在的不稳定的情况，企业怎么确保服务的延续性？没有一款良好的工具也是难以做到的。",
        },
        {
          text: "实施流程透明化，服务交付标准化",
          value: 3,
          content:
            "全屋智能的服务是一项很大的工程，不是单纯的设备堆积，而是各个工种的人员、设备以及业主相互配合来打造的。对于大型品牌企业，基本都是建立了一套适合自身发展的全屋智能实施标准的。但是标准定下来之后，如何确保各个项目都能按照标准执行和交付呢？光靠人力来检查是困难的，没有一套完善的数字化工具是难以对分布在全国的所有项目进行监管的，这也是和慕PMS对于品牌商的价值。",
        },
        {
          text: "数据有沉淀，问题可追溯，策略可优化",
          value: 4,
          content:
            "企业客户资源、运营数据分散在各个经销商、各个服务人员手中，企业难以无法获得，而且整个销售过程不透明，业务数据无法沉淀，数据利用难以最大化，设计、生产、销售、服务、售后等所有核心节点无法形成完整的链接，难以优化和调整决策。没有数据的沉淀，企业产品和服务的质量管理也是不好做的，一旦出现问题很难定位和解决。原始数据记录对管理非常重要，尽可能地做到“管理数据化，流程表单化”，为“品质问题追溯”留下宝贵的第一手资料。",
        },
      ],
      checkedValue: 0,
    };
  },
  created(){
    this.windowResize();
    window.addEventListener('resize',this.windowResize);
  },
  mounted(){
    this.slideBanner();
  },
  beforeDestroy(){
    window.removeEventListener('resize',this.windowResize);
  },
  methods: {
    windowResize(){
      if(window.innerWidth <= 749){
        this.autoplay = true;
      }else{
        this.autoplay = false;
      }
    },
    mouseEnter(index) {
      this.checkedValue = index;
      this.$refs["carousel"].setActiveItem(index);
    },
    carouselChange(e) {
      this.checkedValue = e;
    },
    // 滑动切换
    slideBanner() {
      //选中的轮播图
      var box = document.querySelector(".top-left");
      var startPoint = 0;
      var stopPoint = 0;
      //重置坐标
      var resetPoint = function () {
        startPoint = 0;
        stopPoint = 0;
      };
      //手指按下
      box.addEventListener("touchstart", function (e) {
        //手指点击位置的X坐标
        startPoint = e.changedTouches[0].pageX;
      });
      //手指滑动
      box.addEventListener("touchmove", function (e) {
        //手指滑动后终点位置X的坐标
        stopPoint = e.changedTouches[0].pageX;
      });
      //当手指抬起的时候，判断图片滚动离左右的距离
      let that = this;
      box.addEventListener("touchend", function () {
        if (stopPoint == 0 || startPoint - stopPoint == 0) {
          resetPoint();
          return;
        }
        if (startPoint - stopPoint > 0) {
          resetPoint();
          that.$refs.carousel.next();
          return;
        }
        if (startPoint - stopPoint < 0) {
          resetPoint();
          that.$refs.carousel.prev();
          return;
        }
      });
    },
  },
};
</script>

<style scoped lang='scss'>
.third-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f2f5fa;
  .third-header {
    padding-top: 80px;
    padding-bottom: 50px;
    font-size: 44px;
    font-weight: 500;
    @media screen and (max-width: 1299px) {
      font-size: 40px;
    }
    @media screen and (max-width: 1000px) {
      font-size: 36px;
    }
    @media screen and (max-width: 749px) {
      font-size: 32px;
      padding-top: 50px;
      padding-bottom: 45px;
    }
  }
  .third-main {
    width: 1200px;
    height: 540px;
    background: rgba(255, 255, 255, 1);
    border-radius: 25px;
    margin-bottom: 140px;
    overflow: hidden;
    @media screen and (max-width: 1299px) {
      width: 87.89%;
      margin-bottom: 120px;
    }
    @media screen and (max-width: 1000px) {
      width: 83.85%;
      margin-bottom: 100px;
      height: 480px;
    }
    @media screen and (max-width: 749px) {
      width: 85.33%;
      height: 508px;
      margin-bottom: 20px;
    }
    .main-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .top-left {
        position: relative;
        height: 410px;
        width: 960px;
        @media screen and (max-width: 1299px) {
          width: 80%;
        }
        @media screen and (max-width: 1000px) {
          width: 100%;
          height: 370px;
        }
        @media screen and (max-width: 749px) {
          height: 508px;
          ::v-deep .el-carousel__container {
            height: 467px;
          }
        }
        .left-item {
          position: absolute;
          top: 100px;
          left: 90px;
          @media screen and (max-width: 1299px) {
            top: 80px;
            left: 80px;
          }
          @media screen and (max-width: 1000px) {
            top: 60px;
            left: 60px;
          }
          @media screen and (max-width: 749px) {
            top: 40px;
            left: 37px;
          }
          .item-top {
            font-size: 32px;
            font-weight: 500;
            @media screen and (max-width: 1000px) {
              font-size: 28px;
            }
            @media screen and (max-width: 749px) {
              width: calc(100% - 80px);
              text-align: center;
              font-size: 24px;
              line-height: 40px;
            }
          }
          .item-bottom {
            width: 780px;
            font-size: 18px;
            color: #666;
            line-height: 32px;
            margin-top: 23px;
            @media screen and (max-width: 1299px) {
              width: calc(100% - 160px);
            }
            @media screen and (max-width: 1000px) {
              width: calc(100% - 120px);
              font-size: 16px;
              line-height: 30px;
            }
            @media screen and (max-width: 749px) {
              width: calc(100% - 74px);
              line-height: 26px;
              margin-top: 17px;
            }
          }
        }
      }
      .right-img {
        width: 240px;
        height: 410px;
        @media screen and (max-width: 1299px) {
          width: 20%;
        }
        @media screen and (max-width: 1000px) {
          display: none;
        }
      }
    }
    .main-bottom {
      display: flex;
      width: 100%;
      @media screen and (max-width: 749px) {
        display: none;
      }
      .img-cover {
        position: relative;
        width: 20%;
        .inner-img {
          width: 100%;
          height: 130px;
          @media screen and (max-width: 1000px) {
            height: 110px;
          }
        }
        .inner-text {
          width: 100%;
          height: 130px;
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 30px;
          font-size: 20px;
          line-height: 32px;
          box-sizing: border-box;
          text-align: center;
          @media screen and (max-width: 1299px) {
            padding: 0 15px;
            font-size: 18px;
            line-height: 28px;
          }
          @media screen and (max-width: 1000px) {
            height: 110px;
            padding: 0 20px;
            font-size: 13px;
            line-height: 20px;
          }
        }
        .img-active {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 130px;
          background: rgba(255, 255, 255, 0.85);
          @media screen and (max-width: 1000px) {
            height: 110px;
          }
        }
        &:hover {
          cursor: pointer;
        }
      }
      .img-cover-a {
        .img-active {
          background: rgba(47, 86, 238, 0.9);
        }
        .inner-text {
          color: #fff;
        }
      }
    }
  }

  .dot-list {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
    display: none;
    @media screen and (max-width: 749px) {
      display: flex;
    }
    .dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #ccc;
      margin-right: 15px;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        cursor: pointer;
      }
    }
    .dot-active {
      background: #2f56ee;
    }
  }
}
</style>