<template>
  <div class="fifth-screen">
    <div class="fifth-main">
      <div class="main-left">
        <div class="left-top">集团版项目管理<br />生产力工具</div>
        <div class="left-top1">集团版项目管理生产力工具</div>
        <div
          @click="$router.push('businessCooperation')"
          class="appoint-btn d-flex-center"
        >
          预约演示
        </div>
      </div>
      <img class="right-img" src="../../assets/images/b-fifth-bg.png" alt="" />
    </div>
    <CommonFooter />
  </div>
</template>

<script>
import CommonFooter from "@/components/CommonFooter";
export default {
  data() {
    return {};
  },
  components: {
    CommonFooter,
  },
};
</script>

<style scoped lang='scss'>
.fifth-screen {
  width: 100%;
  .fifth-main {
    width: 100%;
    height: 31.25vw;
    // background: linear-gradient(to right, #d8dff1 0%, #ebeff8 35%, #fbfcfe 100%);
    background-image: url('../../assets/images/b-fifth-cover.png');
    background-size: 100% 100%;
    box-sizing: border-box;
    padding-left: 13vw;
    padding-right: 1.916vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 1299px) {
      height: 500px;
      padding-left: 9.765%;
      padding-right: 6.835%;
    }
    @media screen and (max-width: 1000px) {
      height: 650px;
      flex-direction: column;
      justify-content: center;
      padding-left: 0;
      padding-right: 0;
    }
    @media screen and (max-width: 749px) {
      height: auto;
      padding-top: 62px;
      padding-bottom: 44px;
    }
    .main-left {
      @media screen and (max-width: 1000px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .left-top {
        font-size: 2.60416vw;
        font-weight: 500;
        line-height: 3.75vw;
        @media screen and (max-width: 1299px) {
          font-size: 36px;
          line-height: 52px;
        }
        @media screen and (max-width: 1000px) {
          display: none;
        }
      }
      .left-top1 {
        font-size: 36px;
        line-height: 52px;
        display: none;
        font-weight: 500;
        @media screen and (max-width: 1000px) {
          display: block;
        }
        @media screen and (max-width: 749px) {
          font-size: 32px;
          padding: 0 72px;
          line-height: 48px;
          
          text-align: center;
        }
      }
      .appoint-btn {
        width: 8.333333vw;
        height: 2.916666vw;
        background: linear-gradient(90deg, #3c83fe 0%, #103def 100%);
        border-radius: 1.5625vw;
        font-size: 1.14583vw;
        color: #fff;
        margin-top: 3.593vw;
        @media screen and (max-width: 1299px) {
          width: 120px;
          height: 44px;
          border-radius: 30px;
          font-size: 16px;
        }
        @media screen and (max-width: 1000px) {
          margin-top: 40px;
        }
        @media screen and (max-width: 749px) {
          margin-top: 22px;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
    .right-img {
      height: 28.125vw;
      @media screen and (max-width: 1299px) {
        width: 51.96%;
      }
      @media screen and (max-width: 1000px) {
        width: auto;
        height: 56.462%;
        margin-top: 40px;
      }
      @media screen and (max-width: 749px) {
        width: 85.33%;
        height:auto;
        margin-top: 60px;
      }
    }
  }
}
</style>