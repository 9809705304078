<template>
  <div class="second-screen">
    <div class="second-top d-flex-center">功能介绍</div>
    <div class="menu-header">
      <div
        @mouseenter="changeHeaderItem(index)"
        :class="[headerItem === index ? 'header-item-active' : '']"
        v-for="(item, index) in headerText"
        :key="index"
        class="header-item"
      >
        {{ item }}
      </div>
    </div>
    <div class="line"></div>
    <el-carousel
      ref="carousel"
      class="menu-cover"
      :autoplay="false"
      arrow="never"
      indicator-position="none"
      :interval="4000"
    >
      <el-carousel-item :initial-index="0" class="menu-main">
        <div class="main-left">
          <div class="left-top">项目流程管理</div>
          <div class="left-list">
            项目流程全覆盖<br />
            项目组协同办公<br />
            历史操作全记录
          </div>
          <div @click="$router.push('/businessCooperation')" class="left-bottom">
            <span class="look">预约演示</span>
            <span class="el-icon-arrow-right"></span>
          </div>
        </div>
        <img
          class="right-img"
          src="../../assets/images/b-second-1.png"
          alt=""
        />
      </el-carousel-item>
      <el-carousel-item :initial-index="1" class="menu-main">
        <div class="main-left">
          <div class="left-top">文档集中存储</div>
          <div class="left-list">
            文件按项目存储，按用途分类<br />
            文件云端同步，随时查看<br />
            文档集中管理，员工离职不丢失
          </div>
          <div @click="$router.push('/businessCooperation')" class="left-bottom">
            <span class="look">预约演示</span>
            <span class="el-icon-arrow-right"></span>
          </div>
        </div>
        <img
          class="right-img"
          src="../../assets/images/b-second-2.png"
          alt=""
        />
      </el-carousel-item>
      <el-carousel-item :initial-index="2" class="menu-main">
        <div class="main-left">
          <div class="left-top">组织架构管理</div>
          <div class="left-list">
            统一管理团队成员权限<br />
            任务安排更方便，项目组灵活组建<br />
            工作交接同时项目权限自动交接
          </div>
          <div @click="$router.push('/businessCooperation')" class="left-bottom">
            <span class="look">预约演示</span>
            <span class="el-icon-arrow-right"></span>
          </div>
        </div>
        <img
          class="right-img"
          src="../../assets/images/b-second-3.png"
          alt=""
        />
      </el-carousel-item>
      <el-carousel-item :initial-index="3" class="menu-main">
        <div class="main-left">
          <div class="left-top">集团服务</div>
          <div class="left-list">
            统一调遣服务人员<br />
            服务流程透明化、标准化<br />
            服务过程和文档全记录<br />
            服务数据统计和报表
          </div>
          <div @click="$router.push('/businessCooperation')" class="left-bottom">
            <span class="look">预约演示</span>
            <span class="el-icon-arrow-right"></span>
          </div>
        </div>
        <img
          class="right-img"
          src="../../assets/images/b-second-4.png"
          alt=""
        />
      </el-carousel-item>
      <el-carousel-item :initial-index="4" class="menu-main">
        <div class="main-left">
          <div class="left-top">数据统计和报表</div>
          <div class="left-list">
            项目数据全面统计分析<br />
            团队工作情况随时了解<br />
            详实报表，可导出excel
          </div>
          <div @click="$router.push('/businessCooperation')" class="left-bottom">
            <span class="look">预约演示</span>
            <span class="el-icon-arrow-right"></span>
          </div>
        </div>
        <img
          class="right-img"
          src="../../assets/images/b-second-5.png"
          alt=""
        />
      </el-carousel-item>
    </el-carousel>
    <div class="small-cover">
      <div class="small-item">
        <div class="small-item-top">项目流程管理</div>
        <div class="small-item-list">
          项目流程全覆盖<br />
          项目组协同办公<br />
          历史操作全记录
        </div>
        <div @click="$router.push('/businessCooperation')" class="appoint-btn">
          <span>预约演示</span>
          <span class="el-icon-arrow-right"></span>
        </div>
        <div class="img-cover">
          <img
            class="bottom-img"
            src="../../assets/images/b-second-1.png"
            alt=""
          />
        </div>
      </div>
      <div class="small-item">
        <div class="small-item-top">文档集中存储</div>
        <div class="small-item-list">
          文件按项目存储，按用途分类<br />
          文件云端同步，随时查看<br />
          文档集中管理，员工离职不丢失
        </div>
        <div @click="$router.push('/businessCooperation')" class="appoint-btn">
          <span>预约演示</span>
          <span class="el-icon-arrow-right"></span>
        </div>
        <div class="img-cover">
          <img
            class="bottom-img"
            src="../../assets/images/b-second-2.png"
            alt=""
          />
        </div>
      </div>
      <div class="small-item">
        <div class="small-item-top">组织架构管理</div>
        <div class="small-item-list">
          统一管理团队成员权限<br />
          任务安排更方便，项目组灵活组建<br />
          工作交接同时项目权限自动交接
        </div>
        <div @click="$router.push('/businessCooperation')" class="appoint-btn">
          <span>预约演示</span>
          <span class="el-icon-arrow-right"></span>
        </div>
        <div class="img-cover">
          <img
            class="bottom-img"
            src="../../assets/images/b-second-3.png"
            alt=""
          />
        </div>
      </div>
      <div class="small-item">
        <div class="small-item-top">集团服务</div>
        <div class="small-item-list">
          统一调遣服务人员<br />
          服务流程透明化、标准化<br />
          服务过程和文档全记录<br />
          服务数据统计和报表
        </div>
        <div @click="$router.push('/businessCooperation')" class="appoint-btn">
          <span>预约演示</span>
          <span class="el-icon-arrow-right"></span>
        </div>
        <div class="img-cover">
          <img
            class="bottom-img"
            src="../../assets/images/b-second-4.png"
            alt=""
          />
        </div>
      </div>
      <div class="small-item">
        <div class="small-item-top">数据统计和报表</div>
        <div class="small-item-list">
          项目数据全面统计分析<br />
          团队工作情况随时了解<br />
          详实报表，可导出Excel<br />
        </div>
        <div @click="$router.push('/businessCooperation')" class="appoint-btn">
          <span>预约演示</span>
          <span class="el-icon-arrow-right"></span>
        </div>
        <div class="img-cover">
          <img
            class="bottom-img"
            src="../../assets/images/b-second-5.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headerText: [
        "项目流程管理",
        "文档集中存储",
        "组织架构管理",
        "集团服务",
        "数据统计与报表",
      ],
      headerItem: 0,
    };
  },
  components: {},
  mounted() {
    this.changeHeaderItem(0);
  },
  methods: {
    changeHeaderItem(index) {
      this.headerItem = index;
      this.$refs["carousel"].setActiveItem(index);
    }
  },
};
</script>

<style scoped lang='scss'>
.second-screen {
  width: 100%;
  .second-top {
    padding-top: 100px;
    padding-bottom: 85px;
    font-size: 44px;
    font-weight: 500;
    @media screen and (max-width: 1299px) {
      font-size: 40px;
      padding-top: 80px;
      padding-bottom: 60px;
    }
    @media screen and (max-width: 1000px) {
      font-size: 36px;
    }
    @media screen and (max-width: 749px) {
      font-size: 32px;
      padding-top: 60px;
      padding-bottom: 50px;
    }
  }
  .menu-header {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    position: relative;
    @media screen and (max-width: 1299px) {
      width: 87.89%;
    }
    @media screen and (max-width: 1000px) {
      width: 83.85%;
    }
    @media screen and (max-width: 749px) {
      width: 85.33%;
    }
    .header-item {
      font-size: 22px;
      padding-bottom: 10px;
      border-bottom: 3px solid transparent;
      @media screen and (max-width: 1299px) {
        font-size: 18px;
        padding-bottom: 11px;
      }
      @media screen and (max-width: 1000px) {
        font-size: 16px;
        padding-bottom: 10px;
      }
      @media screen and (max-width: 749px) {
        display: none;
      }
      &:hover {
        cursor: pointer;
      }
    }
    .header-item-active {
      border-bottom: 3px solid rgba(47, 86, 238, 1);
      color: rgba(47, 86, 238, 1);
    }
  }
  .line {
    width: 100%;
    height: 0.5px;
    background: #ccc;
    margin-bottom: 40px;
    @media screen and (max-width: 749px) {
      display: none;
    }
  }
  .menu-cover {
    width: 1200px;
    height: 520px;
    background: linear-gradient(91deg, #f8faff 0%, #cbdaf4 100%);
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: 80px;
    @media screen and (max-width: 1299px) {
      width: 87.89%;
      height: 420px;
    }
    @media screen and (max-width: 1000px) {
      width: 83.85%;
      height: 320px;
    }
    @media screen and (max-width: 749px) {
      display: none;
    }
    .menu-main {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 1200px;
      height: 520px;
      padding: 50px;
      padding-left: 120px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media screen and (max-width: 1299px) {
        width: 100%;
        height: 420px;
        padding: 43px 50px 43px 100px;
      }
      @media screen and (max-width: 1000px) {
        height: 320px;
        padding: 38px 20px 38px 60px;
      }
      .main-left {
        .left-top {
          font-size: 36px;
          font-weight: 500;
          @media screen and (max-width: 1299px) {
            font-size: 32px;
          }
          @media screen and (max-width: 1000px) {
            font-size: 28px;
          }
        }
        .left-list {
          margin-top: 21px;
          line-height: 40px;
          font-size: 22px;
          @media screen and (max-width: 1299px) {
            margin-top: 21px;
            line-height: 36px;
            font-size: 18px;
          }
          @media screen and (max-width: 1000px) {
            margin-top: 24px;
            line-height: 28px;
            font-size: 16px;
          }
        }
        .left-bottom {
          font-size: 18px;
          color: #2f56ee;
          margin-top: 31px;
          @media screen and (max-width: 1000px) {
            margin-top: 34px;
            font-size: 16px;
          }
          &:hover {
            cursor: pointer;
          }
        }
      }
      .right-img {
        width: 580px;
        height: 440px;
        @media screen and (max-width: 1299px) {
          width: 440px;
          height: 333px;
        }
        @media screen and (max-width: 1000px) {
          width: 320px;
          height: 242px;
        }
      }
    }
    .is-animating {
      transition: transform 0.5s ease-in-out;
    }
    .is-active {
      z-index: 2;
    }
  }

  .small-cover {
    width: 100%;
    display: none;
    @media screen and (max-width: 749px) {
      display: block;
    }
    .small-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 70px;
      .small-item-top {
        font-size: 24px;
        font-weight: 500;
        line-height: 40px;
      }
      .small-item-list {
        font-size: 16px;
        color: #666;
        line-height: 28px;
        margin-top: 11px;
      }
      .appoint-btn {
        margin-top: 19px;
        font-size: 16px;
        color: #2f56ee;
      }
      .img-cover {
        width: 83.55vw;
        background: linear-gradient(91deg, #F4F6FC 0%, #CBDEF4 100%);
        border-radius: 10px;
        margin-top: 30px;
        .bottom-img {
          width: 100%;
        }
      }
    }
  }
}
</style>