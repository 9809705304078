<template>
  <div class="first-screen">
    <div class="first-main">
      <div class="main-top">集团版项目管理生产力工具</div>
      <div class="main-middle">
        项目管理、文档管理、组织架构管理、跨部门协同，集团服务、数据统计和报表
      </div>
      <div
        @click="$router.push('businessCooperation')"
        class="bottom-btn d-flex-center"
      >
        预约演示
      </div>
    </div>
    <img class="right-img" src="../../assets/images/b-first-bg.png" alt="" />
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
};
</script>

<style scoped lang='scss'>
.first-screen {
  width: 100%;
  height: calc(42.708vw + 70px);
  background: linear-gradient(229deg, #d8dff1 0%, #ebeff8 35%, #fbfcfe 100%);
  position: relative;
  padding-top: 70px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 1299px) {
    height: 610px;
  }
  @media screen and (max-width: 1000px) {
    height: 1000px;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
  }
  @media screen and (max-width: 749px) {
    height: 759px;
  }
  .right-img {
    position:absolute;
    right: 0;
    bottom: 0;
    width: 48.43vw;
    @media screen and (max-width: 1299px) {
      width: 51.757vw;
    }
    @media screen and (max-width: 1000px) {
      position: static;
      width: auto;
      height: 51%;
    }
    @media screen and (max-width: 749px) {
      height: 38.998%;
    }
  }
  .first-main {
    height: 100%;
    padding-left: 9.375%;
    margin-right: 55%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-width: 1299px) {
      padding-left: 8.789%;
      margin-right: 54%;
    }
    @media screen and (max-width: 1000px) {
      width: 100%;
      padding-left: 0;
      margin-right: 0;
      align-items: center;
    }
    .main-top {
      font-size: 3vw;
      font-weight: 500;
      line-height: 4vw;
      @media screen and (max-width: 1299px) {
        font-size: 43px;
        line-height: 60px;
      }
      @media screen and (max-width: 749px) {
        font-size: 32px;
        padding: 0 53px;
        line-height: 48px;
        text-align: center;
      }
    }
    .main-middle {
      font-size: 1.25vw;
      line-height: 2.083vw;
      margin-top: 1.614vw;
      @media screen and (max-width: 1299px) {
        font-size: 18px;
        line-height: 32px;
        margin-top: 24.5px;
      }
      @media screen and (max-width: 749px) {
        font-size: 14px;
        line-height: 26px;
        margin-top: 16px;
        padding: 0 58px;
        text-align: center;
      }
    }
    .bottom-btn {
      width: 8.33vw;
      height: 2.927vw;
      background: linear-gradient(90deg, #3c83fe 0%, #103def 100%);
      border-radius: 1.5625vw;
      font-size: 1.14583vw;
      color: #fff;
      margin-top: 5.8333vw;
      @media screen and (max-width: 1299px) {
        width: 120px;
        height: 44px;
        border-radius: 30px;
        font-size: 16px;
        margin-top: 53px;
      }
      @media screen and (max-width: 749px) {
        margin-top: 44px;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>