<template>
  <div class="business">
    <BusinessHeader/>
    <firstScreen/>
    <SecondScreen/>
    <ThirdScreen/>
    <ForthScreen/>
    <FifthScreen/>
  </div>
</template>

<script>
import BusinessHeader from './businessHeader';
import firstScreen from './firstScreen';
import SecondScreen from './secondScreen';
import ThirdScreen from './thirdScreen';
import ForthScreen from './forthScreen';
import FifthScreen from './fifthScreen';
export default {
  components: {
    BusinessHeader,
    firstScreen,
    SecondScreen,
    ThirdScreen,
    ForthScreen,
    FifthScreen
  },
  data () {
    return {
      
    };
  }
};
</script>

<style scoped lang='scss'>
</style>