<template>
  <div class="business-header">
    <div @click="$router.push('/')" class="header-logo">
      <img class="logo-img" src="../../assets/images/header-logo.png" alt="" />
    </div>
    <div
      @click="$router.push('businessCooperation')"
      class="concat-btn d-flex-center"
    >
      联系我们
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
};
</script>

<style scoped lang='scss'>
.business-header {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100vw;
  height: 70px;
  padding: 0 50px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(242, 243, 245, 0);
  -webkit-backdrop-filter: blur(24px);
  backdrop-filter: blur(24px);
  @media screen and (max-width: 749px) {
    padding: 0 20px;
  }
  .header-logo {
    &:hover {
      cursor: pointer;
    }
    .logo-img {
      width: 144px;
      height: 52px;
    }
  }
  .concat-btn {
    width: 110px;
    height: 40px;
    background: rgba(47, 86, 238, 1);
    border-radius: 30px;
    color: #fff;
    @media screen and (max-width: 749px) {
      width: 90px;
      height: 28px;
      font-size: 14px;
    }
    &:hover {
      cursor: pointer;
      background: #515151;
    }
  }
}
</style>